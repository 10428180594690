import React from "react";

import c from "classnames";
import { Toaster } from "react-hot-toast";

import Styles from "./layout.module.scss";

import { Header } from "../header";

type Props = {
	children: React.ReactNode;
	page: string;
};

export const Layout = ({ children, page }: Props) => {
	return (
		<div className={c(Styles.body)}>
			<Toaster
				position="bottom-right"
				containerClassName={Styles.toastCont}
				toastOptions={{
					// Define default options
					className: Styles.toast,
					duration: 5000,
					success: {
						iconTheme: {
							primary: "#274c46",
							secondary: "white",
						},
					},
				}}
			/>
			<div id={Styles.mainContent}>
				<Header page={page} />
				<div className="container containerLarge">
					{/* <div className={Styles.logo}>Pure Pet Food</div> */}
					{children}
				</div>
			</div>
		</div>
	);
};

import React from "react";

import c from "classnames";

import Styles from "./heading.module.scss";

type Props = {
	heirarchy: "h1" | "h2" | "h3" | "h4" | "h5" | "h6";
	styling?: string;
	children: React.ReactNode;
	className?: string;
	onClick?: () => void;
};

export const Heading = ({
	heirarchy = "h2",
	className,
	styling = heirarchy,
	children,
	onClick,
}: Props) => {
	const Title = heirarchy;

	return (
		<Title
			className={c(Styles.heading, Styles[styling], className)}
			onClick={onClick}
		>
			{children}
		</Title>
	);
};

import React, { useState } from "react";

import c from "classnames";
import Link from "next/link";
import { signOut, useSession } from "next-auth/react";
import { useRouter } from "next/router";
import Image from "next/image";

import { ViewUser } from "@/salesHelpers/user";
import { useUserDataStore } from "@/salesStore/UserDataStore";
import IconMenu from "@/salesComponents/icons/iconMenu.svg";
import IconExpand from "@/salesComponents/icons/iconExpand.svg";
import { permissionLevel } from "@/salesHelpers/helperFunctions";

import Styles from "./header.module.scss";

import { Button } from "../elements/button";
import { Zigzag } from "../elements/zigzag";
import { ProfilePic } from "../elements/profilePic";

type Props = {
	page: string;
};

export const Header = ({ page }: Props) => {
	const [userOpen, setUserOpen] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);

	const router = useRouter();

	const setActiveUser = useUserDataStore((state) => state.setActiveUser);

	// SESSION
	const { data: session } = useSession();

	return (
		<div className={Styles.header}>
			<div className="container containerLarge">
				<div
					className={Styles.burger}
					onClick={() => {
						setMenuOpen(!menuOpen);
					}}
				>
					<IconMenu />
				</div>
				<Link href="/dashboard">
					<a className={Styles.logo}>Pure Pet Food</a>
				</Link>
				<div className={Styles.sellBtnMob}>
					<Button
						use="link"
						link="/sales/"
						type="primary"
						text="Start"
					></Button>
				</div>
				<nav className={c(Styles.nav, { [Styles.isOpen]: menuOpen })}>
					<ul>
						<li>
							<Link href="dashboard">
								<a
									className={c(Styles.navLink, {
										[Styles.active]: page === "dashboard",
									})}
								>
									Dashboard
								</a>
							</Link>
						</li>
						<li>
							<Link href="events">
								<a
									className={c(Styles.navLink, {
										[Styles.active]: page === "events",
									})}
								>
									Events
								</a>
							</Link>
						</li>
						{permissionLevel(session?.user.role) > 1 && (
							<>
								<li>
									<Link href="reports">
										<a
											className={c(Styles.navLink, {
												[Styles.active]: page === "reports",
											})}
										>
											Reports
										</a>
									</Link>
								</li>
								<li>
									<Link href="incentives">
										<a
											className={c(Styles.navLink, {
												[Styles.active]: page === "incentives",
											})}
										>
											Incentives
										</a>
									</Link>
								</li>
							</>
						)}
						<li>
							<Link href="leads">
								<a
									className={c(Styles.navLink, {
										[Styles.active]: page === "leads",
									})}
								>
									Leads
								</a>
							</Link>
						</li>
						<li>
							<Link href="directory">
								<a
									className={c(Styles.navLink, {
										[Styles.active]: page === "directory",
									})}
								>
									Directory
								</a>
							</Link>
						</li>
						<li>
							<Link href="leaderboards">
								<a
									className={c(Styles.navLink, {
										[Styles.active]: page === "leaderboards",
									})}
								>
									Leaderboards
								</a>
							</Link>
						</li>
						<li className={Styles.sellBtnDesktop}>
							<Button
								use="link"
								link="/sales/"
								type="primary"
								text="Start"
							></Button>
						</li>
						<li
							className={c(Styles.withDropdown, { [Styles.isOpen]: userOpen })}
						>
							<span onClick={() => setUserOpen(!userOpen)}>
								<ProfilePic
									firstName={session?.user?.first_name}
									lastName={session?.user?.last_name}
									pic={session?.user?.profile_picture}
								/>
								<IconExpand />
							</span>
							<ul className={Styles.dropdown}>
								<li>
									<button
										type="button"
										onClick={() => {
											ViewUser(session?.user.id || 0, router, setActiveUser);
											setUserOpen(!userOpen);
										}}
										className={c(Styles.dropNavLink)}
									>
										My profile
									</button>
								</li>
								<li>
									<button
										className={c(Styles.dropNavLink)}
										onClick={() => {
											signOut();
											setUserOpen(!userOpen);
										}}
									>
										Logout
									</button>
								</li>
							</ul>
						</li>
					</ul>
				</nav>
			</div>
			<Zigzag />
		</div>
	);
};

import React from "react";

import Styles from "./zigzag.module.scss";


type Props = {
	progress?: number | null;
};


export const Zigzag = ({progress}:Props) => {

	return (
		<div className={Styles.zigzagDivider}>
			<div className={Styles.zigzag}></div>
			{progress && (
				<div className={Styles.zigzagProgress} style={{width:progress}}></div>
			)}
		</div>
	);
};

import React from "react";

import c from "classnames"

import Styles from "./divider.module.scss";


type Props = {
	type?: "or" | "normal";
};

export const Divider = ({ type = "normal" }: Props) => {

	return (
		<div className={c(Styles.divider, {[Styles.dividerText]:type==="or"})}>{type === "or" && "Or"}</div>
	)
};

import React from "react";

import c from "classnames";
import { Toaster } from "react-hot-toast";

import { Divider } from "@/salesComponents/elements/divider";
import { Button } from "@/salesComponents/elements/button";

import Styles from "./layout.module.scss";

type Props = {
	children: React.ReactNode;
};

export const IntroLayout = ({ children }: Props) => {
	return (
		<div className={c(Styles.body)}>
			<Toaster
				position="bottom-right"
				containerClassName={Styles.toastCont}
				toastOptions={{
					// Define default options
					className: Styles.toast,
					duration: 5000,
					success: {
						iconTheme: {
							primary: "#274c46",
							secondary: "white",
						},
					},
				}}
			/>
			<div id={Styles.mainContent}>
				<div className="container">
					<div className={Styles.logo}>Pure Pet Food</div>
					<div className={Styles.introCont}>{children}</div>
					<div className={Styles.orSalesCont}>
						<Divider type="or" />
						<Button type="primary" text="Start" use="link" link="/sales/" />
					</div>
				</div>
			</div>
		</div>
	);
};
